import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['add', 'destroy', 'refresh']
    static values = {
	examinationIndex: Number
    }

    connect() {}

    addStep() {
	document.getElementById('foobar').value = this.examinationIndexValue;
        this.addTarget.click();
    }

    deleteStep() {
    this.destroyTarget.closest('fieldset').style.display = 'none';
    this.destroyTarget.value = '1';
	this.refreshTarget.click();
    }
}

import { Controller } from "stimulus"

export default class extends Controller {
    connect() {

 

        Array.from(document.getElementsByClassName("gs-results-cell")).forEach(function(element) {

            element.addEventListener('click', (event) => {
                element.nextElementSibling.classList.toggle("active");
               })
        });

        Array.from(document.getElementsByClassName("feedback-cont")).forEach(function(element) {

            element.addEventListener('click', (event) => {
                console.log(element.children);
                element.children[0].classList.toggle("active");
               })
        });


    }

  
}

import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["examinationStep", "id", "open", "checkbox"];
    static values = {
        examination: String, 
        open_examination: String,
        diagnostic_chosen: String,
        checkbox: String
    }

    connect() {
     

        console.log("diagnostic");

        console.log(this.examinationValue);

        if(this.examinationValue !== ''){
            this.setExamination(this.examinationValue);
        }
        if(this.examinationValue === 'finished'){
            this.openDiagnostic();
        }
        document.getElementById('verstanden_btn').addEventListener('click', (event) => {
            document.getElementById('diagnostic_background').classList.remove('start_inactive');
            document.getElementById('left_back').classList.remove('start_inactive');
    
          })


        let checkboxes = document.getElementsByClassName("checkbox_img");
        for(let i=0; i<checkboxes.length; i++){
            let checkbox = checkboxes[i];
            checkbox.addEventListener('click', (event) => {
               let checkbox = event.target;

               if(checkbox.classList.contains('unchecked')){
                     this.clearCheckboxes();
                     checkbox.classList.remove('unchecked');
                     checkbox.classList.add('checked');
                     checkbox.nextElementSibling.checked = true;

               }else{
                checkbox.classList.add('unchecked');
                checkbox.classList.remove('checked');
                checkbox.nextElementSibling.checked = false;

               }
             })
        }

        let nebencheckboxes = document.getElementsByClassName("nebencheckbox_img");
        for(let i=0; i<nebencheckboxes.length; i++){
            let checkbox = nebencheckboxes[i];
            checkbox.addEventListener('click', (event) => {
               let checkbox = event.target;

               if(checkbox.classList.contains('unchecked')){
                     checkbox.classList.remove('unchecked');
                     checkbox.classList.add('checked');
                     checkbox.nextElementSibling.checked = true;

               }else{
                checkbox.classList.add('unchecked');
                checkbox.classList.remove('checked');
                checkbox.nextElementSibling.checked = false;

               }
             })
        }
        
        if(document.getElementById('baz_start').classList.contains('inactive')){
            document.getElementById('diagnostic_background').classList.remove('start_inactive');
            document.getElementById('left_back').classList.remove('start_inactive');
     
        }
   
    }

    openExamination(evt) {

        let id;
        let clicked;
        if(evt.target.classList.contains('examination_icon')){
            id = evt.target.parentElement.getAttribute('data-examination');
            clicked = evt.target.parentElement.getAttribute('data-clicked');
        }else{
            id = evt.target.getAttribute('data-examination');
            clicked = evt.target.getAttribute('data-clicked');
        }

        document.getElementById("baz_start").classList.add("inactive");
        this.idTarget.value = id + "," + clicked;
        let submit = document.getElementsByClassName("open_button");
        submit[0].click();
       
    }

    openDiagnostic(evt) {

        document.getElementById("diagnose_modal").classList.remove("inactive");
        document.getElementById("left_diag").classList.add("inactive");
        document.getElementById("right_diag").classList.add("inactive");

       
    }

    setExamination(id){
        let start_text = document.getElementsByClassName("baz_start");
        start_text[0].classList.add("inactive");
        this.examinationStepTargets
        .forEach(x => x.classList.remove('examination-step-display'));
         this.examinationStepTargets
        .filter(x => x.getAttribute('data-examination') === id)
        .forEach(x => x.classList.add('examination-step-display'));
    }

    advanceExamination(evt) {
        console.log(this.idTarget);
        let examination_id = evt.target.parentElement.parentElement.getAttribute('data-examination');
        console.log(examination_id);
        let inputs = document.getElementsByName("examination");
        for (let i=0; i< inputs.length; i++){
            let elem = inputs[i];
            elem.value = examination_id;
        }
        this.idTarget.value = examination_id;
        console.log(this.idTarget);
        let submit = document.getElementsByClassName("advance_button");
        submit[0].click();
    }

    submitDiagnostic(evt){

        let checked_ids = "";
        let checkboxes = document.getElementsByClassName("diagnostic_checkbox");
        let nebencheckboxes = document.getElementsByClassName("nebendiagnostic_checkbox");
    

        for(let i=0; i<checkboxes.length; i++){
            let checkbox = checkboxes[i];
            if(checkbox.checked === true){
                let value = checkbox.value;
                checked_ids = checked_ids + value + ",";
            }
        }
        for(let i=0; i<nebencheckboxes.length; i++){
            let checkbox = nebencheckboxes[i];
            if(checkbox.checked === true){
                let value = checkbox.value;
                checked_ids = checked_ids + value + ",";
            }
        }
        console.log(checked_ids);

        this.checkboxTarget.value = checked_ids;
        document.getElementById('submitDiagnostic').value = checked_ids;
        let submit = document.getElementsByClassName("submit_button");
        submit[0].click();

    }

    retrieveLabValue(evt) {
        // hmmmmmm
    }

    clearCheckboxes(){

        let checkboxes = document.getElementsByClassName("checkbox_img");

        for(let i=0; i<checkboxes.length; i++){
            let checkbox = checkboxes[i];
            checkbox.classList.remove('checked');
            checkbox.classList.add('unchecked');

            checkbox.nextElementSibling.checked = false;

          
        }
        // let checkboxes = document.getElementsByClassName("diagnostic_checkbox");

        // for(let i=0; i<checkboxes.length; i++){
        //     let checkbox = checkboxes[i];
        //     checkbox.checked = false;
          
        // }
    }


    closeModal(){
        document.getElementById("diagnose_modal").classList.add("inactive");
        document.getElementById("left_diag").classList.remove("inactive");
        document.getElementById("right_diag").classList.remove("inactive");

    }

    openLabor(){
        //document.getElementById("diagnose_modal").classList.add("inactive");
        document.getElementById("labor_modal").classList.toggle("inactive");
       

    }
}

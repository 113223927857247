import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['destroy', 'submit']

    connect() {}

    deleteResult() {
        this.destroyTarget.closest('fieldset').style.display = 'none';
        this.destroyTarget.value = '1';
        this.submitTarget.click();
    }
}

import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['add', 'destroy', 'refresh']

    connect() {

        let mini = document.getElementsByClassName("examination_hide");
        for(let i=0; i<mini.length; i++){
           mini[i].addEventListener('click', function(e) { 
   
               if(mini[i].innerHTML === "+"){
                   mini[i].parentNode.parentNode.parentNode.getElementsByClassName('examination_steps_container')[0].classList.remove("hide");
                   mini[i].innerHTML="-";
               }else{
                   mini[i].parentNode.parentNode.parentNode.getElementsByClassName('examination_steps_container')[0].classList.add("hide");
                   mini[i].innerHTML="+";
               }
            }, false);
     }

    }

    addExamination() {
        this.addTarget.click();
    }

    deleteExamination() {
        this.destroyTarget.closest('fieldset').style.display = 'none';
        this.destroyTarget.value = '1';
        this.refreshTarget.click();
    }
}

import { Controller } from "stimulus"

export default class extends Controller {
   
    connect() {

     /*   console.log(document.getElementsByClassName("lab_answer_wrong")[0] !== null);
        if(document.getElementsByClassName("lab_answer_wrong")){
            document.getElementById('fail-audio').play();
        }*/


        function collapseBubble() {

            let btn = document.getElementsByClassName("pat_icon")[0];
            let bubble = document.getElementById('speechbubble');
            bubble.classList.add('collapsed');

            console.log(bubble);
            btn.addEventListener('click', (event) => {
                let btn = event.target;
    
                if(bubble.classList.contains('collapsed')){
                      bubble.classList.remove('collapsed');
                }else{
                    bubble.classList.add('collapsed');
                }
              })
           }
           setTimeout(collapseBubble, 500);

        
        let checkboxes = document.getElementsByClassName("checkbox_img");
        for(let i=0; i<checkboxes.length; i++){
            let checkbox = checkboxes[i];
            checkbox.addEventListener('click', (event) => {
               let checkbox = event.target;

               if(checkbox.classList.contains('unchecked')){
                     this.clearCheckboxes();
                     checkbox.classList.remove('unchecked');
                     checkbox.classList.add('checked');
                     checkbox.nextElementSibling.checked = true;

               }else{
                checkbox.classList.add('unchecked');
                checkbox.classList.remove('checked');
                checkbox.nextElementSibling.checked = false;

               }
             })
        }
    }

    clearCheckboxes(){
        let checkboxes = document.getElementsByClassName("checkbox_img");

        for(let i=0; i<checkboxes.length; i++){
            let checkbox = checkboxes[i];
            checkbox.classList.remove('checked');
            checkbox.classList.add('unchecked');
            checkbox.nextElementSibling.checked = false;
        }
    }

    submitAnamnese(evt){

        //document.getElementById('fail-audio').play();

        let checkboxes = document.getElementsByClassName("anamnese_checkbox");
        for(let i=0; i<checkboxes.length; i++){
            let checkbox = checkboxes[i];
            console.log(checkbox.checked);
            if(checkbox.checked){
                let value = checkbox.id;
                console.log(value);
                document.getElementById('submitAnamnesis').value = value;
                let submit = document.getElementsByClassName("submit_button");
                submit[0].click();
            }
         
        }
    }
}

import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['submit']

    connect() {}

    start() {
        this.submitTarget.click();
    }
}

import {Controller} from "stimulus"

export default class extends Controller {

    connect() {

        console.log("home");

        let tabs = document.getElementsByClassName("header_start");
        console.log(tabs);
        for (let i = 0; i < tabs.length; i++) {
            let tab = tabs[i];

            tab.addEventListener('click', (event) => {
                let t = event.target;
                let id = t.parentNode.getAttribute("data-id");

                let tabs1 = document.getElementsByClassName("tab");
                for (let i = 0; i < tabs1.length; i++) {
                    tabs1[i].classList.remove("inactive");
                }
                let tabs = document.getElementsByClassName("aktiv");
                for (let i = 0; i < tabs.length; i++) {
                    tabs[i].classList.add("inactive");
                }

                t.parentNode.children[0].classList.add("inactive");
                t.parentNode.children[1].classList.remove("inactive");

                let cases = document.getElementsByClassName("cases-wrapper");
                for (let i = 0; i < cases.length; i++) {
                    cases[i].classList.add("inactive");
                }

                document.getElementById(id).classList.remove("inactive");
            })
        }

        if(document.getElementById('exit')){
            document.getElementById('exit').addEventListener('click', (event) => {
                document.getElementById('box_container').classList.add('inactive');
        
              })
        }
        if(document.getElementById('info')){
            document.getElementById('info').addEventListener('click', (event) => {
                document.getElementById('box_container').classList.remove('inactive');
        
              })
        }


    }


}


import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['destroy', 'submit', 'value'];

    connect() {}


    changeOption() {
        this.valueTarget.value = 0;
        this.submitTarget.click();
    }
}

import { Controller } from "stimulus"

export default class extends Controller {

   
    connect() {

        console.log("RESULTS");

   
       if(document.getElementById('exit')){
        document.getElementById('exit').addEventListener('click', (event) => {
            feedback_container.classList.add('inactive');
    
          })
       }

       let btns = document.getElementsByClassName("gs-results-table");
       console.log(btns);


    
    

       

       let feedback_btns = document.getElementsByClassName("spielverlauf");
       console.log(feedback_btns);
       let feedback_container = document.getElementById('punkte_feedback_container');
       for(let i=0; i<feedback_btns.length; i++){
           let btn = feedback_btns[i];
           btn.addEventListener('click', (event) => {
            console.log("click");
              if(feedback_container.classList.contains('inactive')){
                feedback_container.classList.remove('inactive');
              }else{
                feedback_container.classList.add('inactive');
              }
            })
       }








       let multiple_checkboxes = document.getElementsByClassName("multiple_checkbox_img");
       for(let i=0; i<multiple_checkboxes.length; i++){
           let checkbox = multiple_checkboxes[i];
           checkbox.addEventListener('click', (event) => {
              let checkbox = event.target;

              if(checkbox.classList.contains('unchecked')){
                    checkbox.classList.remove('unchecked');
                    checkbox.classList.add('checked');
                    checkbox.nextElementSibling.checked = true;

              }else{
               checkbox.classList.add('unchecked');
               checkbox.classList.remove('checked');
               checkbox.nextElementSibling.checked = false;

              }
            })
       }

    }

  
    
}


import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['submit', 'destroy', 'add'];

    connect() {

        let select = document.getElementsByClassName("therapy_answer_next");
        for(let i=0; i<select.length; i++){
          let element = select[i].getElementsByTagName("select")[0];
          element.onchange = function() {
            var index = this.selectedIndex;
            var inputText = this.children[index].innerHTML.trim();
            select[i].getElementsByTagName("input")[0].value=inputText;
          }
        }

        let select_avatar = document.getElementsByClassName("zeichnung");
        for(let i=0; i<select_avatar.length; i++){
          let element = select_avatar[i];
          element.onchange = function() {
            var index = this.selectedIndex;
            var number = this.children[index].value;
            select_avatar[i].parentNode.getElementsByClassName("avatar_txt")[0].value=number;
          }
        }

     let mini = document.getElementsByClassName("mini");
     for(let i=0; i<mini.length; i++){
        mini[i].addEventListener('click', function(e) { 

            if(mini[i].innerHTML === "+"){
                mini[i].parentNode.getElementsByClassName('klappen')[0].classList.remove("hide");
                mini[i].innerHTML="-";
            }else{
                mini[i].parentNode.getElementsByClassName('klappen')[0].classList.add("hide");
                mini[i].innerHTML="+";
            }

    
         }, false);
  }
    }

    addTherapyStep() {
        console.log(this);
        this.submitTarget.click();
    }

 
    deleteStep() {
        console.log("DELETE");
        this.destroyTarget.closest('fieldset').style.display = 'none';
        this.destroyTarget.value = '1';
        this.submitTarget.click();
    }
 
}

import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['submit', 'destroy', 'add','refresh'];
    static values = {
        therapyStepIndex: Number
    }

    connect() {

        let checkboxes = document.getElementsByClassName("therapy_checkbox");
        for(let i=0; i<checkboxes.length; i++){
            let checkbox = checkboxes[i];
            checkbox.addEventListener('click', (event) => {
                event.preventDefault;
                event.stopImmediatePropagation(); 
               let checkbox = event.target;
            
               if(checkbox.classList.contains('unchecked')){
                     checkbox.classList.remove('unchecked');
                     checkbox.classList.add('checked');
                     checkbox.parentNode.parentNode.getElementsByTagName('select')[0].classList.add("disabled");
                     checkbox.parentNode.parentNode.getElementsByClassName('next_step_loaded')[0].value = '-1';
                     checkbox.parentNode.parentNode.getElementsByTagName('select')[0].selectedIndex = -1;

               }else{
                checkbox.classList.add('unchecked');
                checkbox.classList.remove('checked');
                checkbox.parentNode.parentNode.getElementsByTagName('select')[0].classList.remove("disabled");
                checkbox.parentNode.parentNode.getElementsByTagName('select')[0].selectedIndex = -1;
               }
             })
        }
    }

    addTherapyOption() {
        document.getElementById('bazbat').value = this.therapyStepIndexValue;
        this.addTarget.click();
    }

    deleteOption() {

        this.destroyTarget.closest('fieldset').style.display = 'none';
        this.destroyTarget.value = '1';
        this.refreshTarget.click();
    }
}

import { Controller } from "stimulus"
import { connectDependenciesBlockAndChunkGroup } from "webpack/lib/GraphHelpers";

export default class extends Controller {
  static targets = ['submit']

    connect() {

      let zeichnungen_btns = document.getElementsByClassName("zeichnung_ansehen");
      for(let i=0; i<zeichnungen_btns.length; i++){
        let btn = zeichnungen_btns[i];
        btn.addEventListener('click', (event) => {
          document.getElementById("zeichnungen").classList.remove("inactive");

         })
     }

     document.getElementById("close_zeichnungen").addEventListener('click', (event) => {
      document.getElementById("zeichnungen").classList.add("inactive");

     })

        let tabs = document.getElementsByClassName("edit_tab");
        let boxes = document.querySelectorAll('.edit_tab');
        let cont_tab = document.querySelectorAll('.tab');

       for(let i=0; i<tabs.length; i++){
           let tab = tabs[i];
           
           tab.addEventListener('click', (event) => {
              let t = event.target;
              let id = t.getAttribute("data-id");
              console.log(id);
              //window.location.hash = id;

              boxes.forEach(element => {
                element.classList.remove('active');
              });
              cont_tab.forEach(element => {
                element.classList.remove('active');
              });

              t.classList.add('active');
              document.getElementById(id).classList.add('active');

              if(id === "abschluss"){
                let totalPoints = 1;
                let diagnostic_points = 0;
                let diagnostic_elements = document.getElementsByClassName("exam_p");
                for(let i=0; i<diagnostic_elements.length; i++){
                  let element = diagnostic_elements[i];
                  let points = element.getElementsByTagName("input")[0].value;
                  if(parseInt(points)>0){
                    diagnostic_points = diagnostic_points + parseInt(points);
                  }
               }

               let therapy_points = 0;
               let therapy_elements = document.getElementsByClassName("ther_p");
               for(let i=0; i<therapy_elements.length; i++){
                 let element = therapy_elements[i];
                 let points = element.getElementsByTagName("input")[0].value;
                 if(parseInt(points)>0){
               
                  therapy_points = therapy_points + parseInt(points);
                 }
              }
         

              //totalPoints = 1 + diagnostic_points + therapy_points;
              console.log(document.getElementById("patient_anamnesis_points").value);
              totalPoints = parseInt(document.getElementById("patient_anamnesis_points").value) + parseInt(document.getElementById("patient_diagnostic_points").value) + parseInt(document.getElementById("patient_therapy_points").value);
              document.getElementById("total_points").innerHTML = "Mögliche Punkte:" + totalPoints;
              document.getElementById("points1").innerHTML = "für Punktzahl 0-" + totalPoints/4;
              document.getElementById("points2").innerHTML = "für Punktzahl " + totalPoints/4 + "-" + (totalPoints/4)*2;
              document.getElementById("points3").innerHTML = "für Punktzahl " + (totalPoints/4)*2 + "-" + (totalPoints/4)*3 ;
              document.getElementById("points4").innerHTML = "für Punktzahl " + (totalPoints/4)*3 + "-" + (totalPoints/4)*4 ;

              }

            })
    }


    //Gender auswählen
    let gender = document.getElementById("patient_gender");
    selectValue(gender);

    //Beim Laden: Naechsten Therapie-Step auswählen
    let steps = document.getElementsByClassName("next_step_loaded");
    //console.log(steps);
    for (let i=0; i<steps.length; i++) {

      if(steps[i].value === "-1"){
        steps[i].parentNode.getElementsByClassName("checkboxes")[0].getElementsByClassName("therapy_checkbox")[0].classList.remove('unchecked');
        steps[i].parentNode.getElementsByClassName("checkboxes")[0].getElementsByClassName("therapy_checkbox")[0].classList.add('checked');
        steps[i].parentNode.getElementsByClassName("checkboxes")[0].getElementsByClassName("therapy_checkbox")[0].checked = true;
        steps[i].parentNode.getElementsByTagName('select')[0].classList.add("disabled");

      }else{
   

        selectValue(steps[i]);
      }
    }

      //Beim Laden: Feedback Avatar auswaehlen
      let avatars = document.getElementsByClassName("avatar_txt");
      //console.log(steps);
      for (let i=0; i<avatars.length; i++) {
        selectValue(avatars[i]);
      }
    


    function selectValue(element){
        let value = element.value;
        element.parentNode.getElementsByTagName('select')[0].value = value;
    }

    
  

 
}

activate_patient() {
  console.log("TEST");
  this.submitTarget.click();
}


}
